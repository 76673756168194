import {
  HydraKeyMap,
  HydraKeyMapType,
  IEditorAction,
  IHydraState,
} from "@gratico/sdk";
import { Signal } from "alfama";
import { ShellProps } from "../../@types";

// Helper function to get the last GROUP ID from active keys
export function getLastGroupId(keyMap: HydraKeyMap[]): string | undefined {
  return keyMap[keyMap.length - 1]?.id;
}

const keyCodeMap: Record<string, string> = {
  " ": "Space",
};

export const startHydra = (props: ShellProps, keyMap: HydraKeyMap[]) => {
  const keyDownHandler = (event: KeyboardEvent) => {
    //    console.log(event);
    const keyCode = keyCodeMap[event.key] || event.key;
    const { $hydra } = props;
    let hydra = $hydra.get();

    if (keyCode === "Escape") {
      if (hydra.keyMap.length > 0) {
        $hydra.set({ keyMap: [] });
      }
      return;
    }
    // Try to find a matching key sequence in the keyMap
    const lastGroupId = getLastGroupId(hydra.keyMap);
    const match = keyMap.find(
      (entry) => entry.key === keyCode && entry.parentId === lastGroupId
    );
    //    console.log({ lastGroupId, keyCode }, keyMap, match);
    if (match && match.type === HydraKeyMapType.GROUP) {
      // Update the signal with the matched group
      return $hydra.set({
        ...hydra,
        keyMap: [...hydra.keyMap, match],
      });
    }
    if (match && match.type === HydraKeyMapType.ACTION) {
      //      console.log(" match.action ", match);
      props.showInput(match.action);
    }
    $hydra.set({
      keyMap: [],
    });
  };

  document.addEventListener("keydown", keyDownHandler);

  return () => {
    document.removeEventListener("keydown", keyDownHandler);
  };
};
